import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-co-so-luu-tru-file-detail',
  templateUrl: './co-so-luu-tru-file-detail.component.html',
  styleUrls: ['./co-so-luu-tru-file-detail.component.css']
})
export class CoSoLuuTruFileDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
