<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Đặt phòng trực tuyến</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="DatPhongTrucTuyenService.formData.ID" type="hidden">
    <div class="col s12 m12 l6">
        <div class="col s12 m12 l4">
            <div class="input-field">
                <label for="BatDau">Nhận phòng</label>
            </div>
        </div>
        <div class="col s12 m12 l8">
            <div class="input-field">
                <input name="BatDau" style="text-align: right; font-weight: bold; width: 100%;" disabled
                    [ngModel]="DatPhongTrucTuyenService.formData.BatDau | date:'yyyy-MM-dd'"
                    (ngModelChange)="onChangeBatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date">
            </div>
        </div>
        <div class="col s12 m12 l4">
            <div class="input-field">
                <label for="KetThuc">Trả phòng</label>
            </div>
        </div>
        <div class="col s12 m12 l8">
            <div class="input-field">
                <input name="KetThuc" style="text-align: right; font-weight: bold; width: 100%;" disabled
                    [ngModel]="DatPhongTrucTuyenService.formData.KetThuc | date:'yyyy-MM-dd'"
                    (ngModelChange)="onChangeKetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Ghi chú</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Ghi chú" name="Note" [(ngModel)]="DatPhongTrucTuyenService.formData.Note" disabled
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Active">Tình trạng</label>
            </div>
        </div>
        <div class="col s8 m8 l8 mt-2 mb-3">
            <div class="input-field">
                <p>
                    <label>
                        <input type="checkbox" class="filled-in" name="Active"
                            [(ngModel)]="DatPhongTrucTuyenService.formData.Active" />
                        <span>Phê duyệt / Chưa phê duyệt</span>
                    </label>
                </p>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="ParentID">Du khách</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select disabled
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="ParentID" class="browser-default" [(ngModel)]="DatPhongTrucTuyenService.formData.ParentID">
                <mat-option *ngFor="let item of ThanhVienService.list" [value]="item.ID">
                    {{item.HoTen}} - {{item.Email}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="CoSoLuuTruID">Cơ sở lưu trú</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select disabled
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="CoSoLuuTruID" class="browser-default"
                [(ngModel)]="DatPhongTrucTuyenService.formData.CoSoLuuTruID">
                <mat-option *ngFor="let item of CoSoLuuTruService.list" [value]="item.ID">
                    {{item.TenDangKyKinhDoanh}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="CoSoLuuTruPhongID">Phòng</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select disabled
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="CoSoLuuTruPhongID" class="browser-default"
                [(ngModel)]="DatPhongTrucTuyenService.formData.CoSoLuuTruPhongID" (selectionChange)="CoSoLuuTruPhongGetAllToListAsync()">
                <mat-option *ngFor="let item of CoSoLuuTruPhongService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SoNguoi">Số người</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="0" name="SoNguoi" [(ngModel)]="DatPhongTrucTuyenService.formData.SoNguoi" disabled
                    type="number">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SoPhong">Số phòng</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="0" name="SoPhong" [(ngModel)]="DatPhongTrucTuyenService.formData.SoPhong" disabled
                    type="number">
            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>