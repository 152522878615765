export const environment = {
  production: false, 
  DomainURL: "https://luutru.tourismhcmc.com/",  
  DomainDestination: "https://luutru.tourismhcmc.com/#/",  
  APIURL: "https://api.tourismhcmc.com/api/v1/",    
  APIRootURL: "https://api.tourismhcmc.com/",    
  LoginURL: "https://login.tourismhcmc.com/",
  Website: "https://tourismhcmc.com/",  
  Image: "Image",  
  Company: "Company", 
  Membership: "Membership", 
  Barcode: "Barcode",
  QRcode: "QRcode",
  OrderDelivery: "OrderDelivery", 
  OrderCall: "OrderCall", 
  Download: "Download",  
  LoadingFile: "loading.gif",
  DialogConfigWidth: "80%",
  InitializationString: "",  
  InitializationNumber: 0, 
  CoSoLuuTruID:26,
  HomestayID:27,
  DiemDuLichID:28,
  NhaHangID:30,
  KhachSanID:31,
  DonViLuHanhID:32,  
  HoChiMinhCityID: 10051, 
  ThanhVienDuKhachID: 3,
  ThanhVienCoSoLuuTruID: 2,
  ThanhVienCoQuanQuanLyID: 1,    
  AuthenticationToken: "AuthenticationToken",
  ThanhVienParentID: "ThanhVienParentID",  
  ThanhVienID: "ThanhVienID",  
  ThanhVienHoTen: "ThanhVienHoTen",
  ThanhVienTaiKhoan: "ThanhVienTaiKhoan",
  ThanhVienAvatarURL: "ThanhVienAvatarURL",
  Homepage: "Homepage",
  UploadSuccess: "Upload thành công.",
  UploadNotSuccess: "Upload không thành công.", 
  SaveSuccess: "Lưu thành công.",
  SaveNotSuccess: "Lưu không thành công.",  
  DeleteConfirm: "Bạn muốn xóa dữ liệu này?",      
  DeleteSuccess: "Xóa thành công.",
  DeleteNotSuccess: "Xóa không thành công.",    
  LoginNotSuccess: "Đăng nhập không thành công.",    
  UserNameExists: "Tài khoản đã tồn tại.",
  UserNameRequired: "UserName là bắt buộc.",    
};

