import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PheDuyet } from 'src/app/shared/PheDuyet.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class PheDuyetService extends BaseService{
    displayColumns: string[] = ['NguoiYeuCauID', 'NoiDungYeuCau', 'NgayYeuCau', 'NgayPheDuyet', 'Active', 'NoiDungPheDuyet', 'Save'];
    //displayColumns: string[] = ['NguoiYeuCauID', 'CoQuanQuanLyNguoiYeuCauID', 'NoiDungYeuCau'];
    list: PheDuyet[] | undefined;
    listSearch: PheDuyet[] | undefined;
    listSearch001: PheDuyet[] | undefined;
    formData!: PheDuyet;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "PheDuyet";
    }   
    GetByParentID_CodeToListAsync(parentID: number, code: string) {
        let url = this.aPIURL + this.controller + '/GetByParentID_CodeToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('parentID', JSON.stringify(parentID));
        formUpload.append('code', JSON.stringify(code));
        return this.httpClient.post(url, formUpload);
    }
}

