<div class="row">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Đổi mật khẩu
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">Cơ sở lưu trú</a>
                        </li>    
                        <li class="breadcrumb-item active" style="color: #ffffff;">Đổi mật khẩu
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <div id="basic-tabs" class="card card card-default scrollspy">
                            <div class="card-content">
                                <div class="card-title">
                                    <div class="row">
                                        <div class="col s12 m12 l8">
                                            <h5 class="card-title" style="font-size: 14px;">
                                                <b>{{ThanhVienService.formData.Name}}</b>
                                            </h5>
                                        </div>
                                        <div class="col s12 m12 l4">
                                            <ul class="tabs">
                                                <li class="tab col m3 s12 p-0"><a class="active p-0" href="#1">Đổi mật khẩu</a></li>                                               
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="1">
                                    <div class="row">
                                        <form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
                                            <div class="col s6 m6 l12">
                                                <div class="card-title">                                                  
                                                    <button
                                                        [disabled]="!ThanhVienService.formData.MatKhau01 || !ThanhVienService.formData.MatKhau02 || !ThanhVienService.formData.MatKhau03 || (ThanhVienService.formData.MatKhau02!=ThanhVienService.formData.MatKhau03)"
                                                        title="Lưu thay đổi" type="submit" style="padding-top: 3px;"
                                                        class="btn-floating waves-effect waves-light cyan"><i
                                                            class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                            <input name="ID" [(ngModel)]="ThanhVienService.formData.ID" type="hidden">                                          
                                            <input name="TaiKhoan" [(ngModel)]="ThanhVienService.formData.TaiKhoan" type="hidden">
                                            <div class="col s12 m12 l6">                                                
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="MatKhau01">Mật khẩu hiện tại</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="MatKhau01"
                                                            [(ngModel)]="ThanhVienService.formData.MatKhau01"
                                                            placeholder="Mật khẩu hiện tại" type="password">
                                                    </div>
                                                </div>                                                
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="MatKhau02">Mật khẩu mới</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="MatKhau02"
                                                            [(ngModel)]="ThanhVienService.formData.MatKhau02"
                                                            placeholder="Mật khẩu mới" type="password">
                                                    </div>
                                                </div>       
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="MatKhau03">Nhập lại mật khẩu mới</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="MatKhau03"
                                                            [(ngModel)]="ThanhVienService.formData.MatKhau03"
                                                            placeholder="Nhập lại mật khẩu mới" type="password">
                                                    </div>
                                                </div>                                             
                                            </div>                                           
                                        </form>
                                    </div>
                                </div>                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />
<app-loading *ngIf="isShowLoading"></app-loading>