<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Hoạt động</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <div class="col s12 m12 l5">
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="DanhMucNgonNguID">Ngôn ngữ</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="DanhMucNgonNguID" class="browser-default"
                [(ngModel)]="CoSoLuuTruHoatDongService.formData.DanhMucNgonNguID">
                <mat-option *ngFor="let item of DanhMucNgonNguService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Tiêu đề</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tiêu đề" name="Name" [(ngModel)]="CoSoLuuTruHoatDongService.formData.Name"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SortOrder">Sắp xếp</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input style="text-align: right;" placeholder="0" name="SortOrder"
                    [(ngModel)]="CoSoLuuTruHoatDongService.formData.SortOrder" type="number">
            </div>
        </div>        
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Code">Hình đại diện</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input type="file" (change)="changeImage($event.target.files)">
            </div>
            <div class="input-field">
                <img class="responsive-img" [src]="CoSoLuuTruHoatDongService.formData.Code">
            </div>
        </div>
    </div>
    <div class="col s12 m12 l7">
        <ck-editor [config]="{'height': 400}" name="Note" [(ngModel)]="CoSoLuuTruHoatDongService.formData.Note"
            skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
    </div>
    <div class="section" *ngIf="ID>0">
        <div class="row">
            <div class="col s12 m12 l12">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="KhungOverflow">
                                <mat-table [dataSource]="PheDuyetDataSource" matSort #PheDuyetSort="matSort">
                                    <ng-container matColumnDef="NguoiYeuCauID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Người yêu cầu
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <div *ngFor="let item of ThanhVienService.list">
                                                <div *ngIf="item.ID==element.NguoiYeuCauID">
                                                    {{item.Email}}
                                                </div>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NgayYeuCau">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày yêu cầu
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            {{element.NgayYeuCau | date:'dd/MM/yyyy h:mm:ss a'}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NoiDungYeuCau">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Yêu cầu
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            {{element.NoiDungYeuCau}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NguoiPheDuyetID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Người Phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <div *ngFor="let item of ThanhVienService.list">
                                                <div *ngIf="item.ID==element.NguoiPheDuyetID">
                                                    {{item.Email}}
                                                </div>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NgayPheDuyet">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày Phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            {{element.NgayPheDuyet | date:'dd/MM/yyyy h:mm:ss a'}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NoiDungPheDuyet">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            {{element.NoiDungPheDuyet}}
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Active">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                            Phê duyệt / Chưa phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <label>
                                                <input type="checkbox" class="filled-in" name="Active{{element.ID}}"
                                                    [(ngModel)]="element.Active" />
                                                <span>.</span>
                                            </label>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="loading">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Đang tải dữ liệu...
                                        </mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="noData">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Chưa có dữ liệu.
                                        </mat-footer-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="PheDuyetService.displayColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: PheDuyetService.displayColumns;"></mat-row>
                                    <mat-footer-row *matFooterRowDef="['loading']"
                                        [ngClass]="{'hide':PheDuyetDataSource!=null}"></mat-footer-row>
                                    <mat-footer-row *matFooterRowDef="['noData']"
                                        [ngClass]="{'hide':!(PheDuyetDataSource!=null && PheDuyetDataSource.data.length==0)}">
                                    </mat-footer-row>
                                </mat-table>
                                <mat-paginator #PheDuyetPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                    [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>