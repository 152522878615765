import { Base } from "./Base.model";

export class CoSoLuuTruSuKien extends Base{
    NgayBatDau?:Date;
    NgayKetThuc?:Date;
    DanhMucNgonNguID?:number;
    Description?: string;
}


