import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTruSuKien } from 'src/app/shared/CoSoLuuTruSuKien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruSuKienService extends BaseService{
    displayColumns: string[] = ['Code', 'Name', 'SortOrder', 'Active', 'Save'];
    list: CoSoLuuTruSuKien[] | undefined;
    listSearch: CoSoLuuTruSuKien[] | undefined;
    listSearch001: CoSoLuuTruSuKien[] | undefined;
    formData!: CoSoLuuTruSuKien;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTruSuKien";
    }
    SaveAndUploadFileAsync(formData: CoSoLuuTruSuKien, fileToUpload: FileList) {
        var thanhVienID = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienID) {
            formData.LastUpdatedMembershipID = Number(thanhVienID);
        }
        let url = this.aPIURL + this.controller + '/SaveAndUploadFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));
        if (fileToUpload) {
            if (fileToUpload.length > 0) {
                for (var i = 0; i < fileToUpload.length; i++) {
                    formUpload.append('file[]', fileToUpload[i]);
                }
            }
        }
        return this.httpClient.post(url, formUpload);
    }
}

