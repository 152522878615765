<div class="row">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">
                        {{this.DanhMucCoSoLuuTruService.formData.Name }}
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">
                            {{this.DanhMucCoSoLuuTruService.formData.Name }}
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <div id="basic-tabs" class="card card card-default scrollspy">
                            <div class="card-content">
                                <div id="1">
                                    <form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
                                        <div class="col s6 m6 l12">
                                            <div class="card-title">
                                                <a title="Danh sách" href="javascript:window.open('','_self').close();"
                                                    style="padding-top: 3px;"
                                                    class="btn-floating waves-effect waves-light green darken-1"><i
                                                        class="material-icons">arrow_back</i></a>&nbsp;&nbsp;&nbsp;

                                                <button [disabled]="!CoSoLuuTruService.formData.TenDangKyKinhDoanh"
                                                    title="Lưu thay đổi" type="submit" style="padding-top: 3px;"
                                                    class="btn-floating waves-effect waves-light cyan"><i
                                                        class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                        <input name="ID" [(ngModel)]="CoSoLuuTruService.formData.ID" type="hidden">
                                        <input name="DanhMucTinhThanhID"
                                            [(ngModel)]="CoSoLuuTruService.formData.DanhMucTinhThanhID" type="hidden">
                                        <div class="col s12 m12 l6">
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="ParentID">Danh mục</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="ParentID" class="browser-default"
                                                        [(ngModel)]="CoSoLuuTruService.formData.ParentID"
                                                        (selectionChange)="DanhMucCoSoLuuTruGetAllToListAsync()">
                                                        <mat-option *ngFor="let item of DanhMucCoSoLuuTruService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="DanhMucQuanHuyenID">Quận Huyện</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucQuanHuyenID" class="browser-default"
                                                        [(ngModel)]="CoSoLuuTruService.formData.DanhMucQuanHuyenID"
                                                        (selectionChange)="DanhMucXaPhuongGetAllToListAsync()">
                                                        <mat-option *ngFor="let item of DanhMucQuanHuyenService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>

                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="DanhMucXaPhuongID">Xã Phường</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucXaPhuongID" class="browser-default"
                                                        [(ngModel)]="CoSoLuuTruService.formData.DanhMucXaPhuongID">
                                                        <mat-option *ngFor="let item of DanhMucXaPhuongService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="TenDangKyKinhDoanh">Tiêu đề</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Tên đăng ký kinh doanh"
                                                        name="TenDangKyKinhDoanh"
                                                        [(ngModel)]="CoSoLuuTruService.formData.TenDangKyKinhDoanh"
                                                        type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="MaSoThue">Mã số thuế</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Mã số thuế" name="MaSoThue"
                                                        [(ngModel)]="CoSoLuuTruService.formData.MaSoThue" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Description">Mô tả</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Mô tả" name="Description"
                                                        [(ngModel)]="CoSoLuuTruService.formData.Description"
                                                        type="text">
                                                </div>
                                            </div>

                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Điện thoại</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Điện thoại" name="DienThoai"
                                                        [(ngModel)]="CoSoLuuTruService.formData.DienThoai" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Email">Email</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Email" name="Email"
                                                        [(ngModel)]="CoSoLuuTruService.formData.Email" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Website">Trang web</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Website" name="Website"
                                                        [(ngModel)]="CoSoLuuTruService.formData.Website" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="DiaChi">Địa chỉ</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <div class="input-field">
                                                        <input placeholder="Địa chỉ" name="DiaChi"
                                                            [(ngModel)]="CoSoLuuTruService.formData.DiaChi" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="KinhDo">Kinh độ</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <div class="input-field">
                                                        <input placeholder="Kinh độ" name="KinhDo"
                                                            [(ngModel)]="CoSoLuuTruService.formData.KinhDo" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="ViDo">Vĩ độ</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <div class="input-field">
                                                        <input placeholder="Vĩ độ" name="ViDo"
                                                            [(ngModel)]="CoSoLuuTruService.formData.ViDo" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="DaXacThuc">Chứng chỉ</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8 mt-2 mb-3">
                                                <div class="input-field">
                                                    <p>
                                                        <label>
                                                            <input type="checkbox" class="filled-in" name="DaXacThuc"
                                                                [(ngModel)]="CoSoLuuTruService.formData.DaXacThuc" />
                                                            <span>Đã xác thực</span>
                                                        </label>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col s12 m12 l6">
                                            <ck-editor [config]="{'height': 400}" name="Note"
                                                [(ngModel)]="CoSoLuuTruService.formData.Note" skin="moono-lisa"
                                                language="en" [fullPage]="true"></ck-editor>
                                            <div class="col s8 m8 l6">
                                                <div class="input-field">
                                                    <label for="Code">Hình đại diện</label>
                                                </div>
                                                <br />
                                                <div class="input-field">
                                                    <input type="file" (change)="changeImage($event.target.files)">
                                                </div>
                                                <div class="input-field">
                                                    <img class="responsive-img" [src]="CoSoLuuTruService.formData.Code">
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l6">
                                                <div class="input-field">
                                                    <label for="QRCodeFile">QR Code</label>
                                                </div>
                                                <br />
                                                <div class="input-field">
                                                    <img class="responsive-img"
                                                        [src]="CoSoLuuTruService.formData.QRCodeFile">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
              

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Chứng chỉ</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="FileSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="FileSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="FileSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="FileAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="FileDataSource" matSort #FileSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="cursor: pointer;" title="{{element.Code}}"
                                                        (click)="FileAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="FileDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a class="btn-floating waves-effect waves-light green darken-1" target="_blank" href="{{element.Code}}" *ngIf="element.Code">
                                                        <i class="material-icons">cloud_download</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruFileService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruFileService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':FileDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(FileDataSource!=null && FileDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #FilePaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Phòng</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="PhongSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="PhongSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="PhongSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="PhongAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="PhongDataSource" matSort #PhongSort="matSort">
                                            <ng-container matColumnDef="Code">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hình đại diện
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Code}}" *ngIf="element.Code"
                                                        title="{{element.Code}}">
                                                        <img class="responsive-img" width="50%" title="{{element.Code}}"
                                                            alt="{{element.Code}}" [src]="element.Code"></a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <a style="cursor: pointer;" title="{{element.Name}}"
                                                        (click)="PhongAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="GiaThapNhat">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Giá cao nhất
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.GiaThapNhat | number:
                                                    '1.0-0'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="GiaCaoNhat">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Giá thấp nhất
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.GiaCaoNhat | number:
                                                    '1.0-0'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="SortOrder">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.SortOrder | number:
                                                    '1.0-0'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in" readonly
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="PhongDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruPhongService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruPhongService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':PhongDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(PhongDataSource!=null && PhongDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #PhongPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Hoạt động</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="HoatDongSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="HoatDongSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="HoatDongSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="HoatDongAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="HoatDongDataSource" matSort #HoatDongSort="matSort">
                                            <ng-container matColumnDef="Code">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hình đại diện
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Code}}" *ngIf="element.Code"
                                                        title="{{element.Code}}">
                                                        <img class="responsive-img" width="50%" title="{{element.Code}}"
                                                            alt="{{element.Code}}" [src]="element.Code"></a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <a style="cursor: pointer;" title="{{element.Name}}"
                                                        (click)="HoatDongAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="SortOrder">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.SortOrder | number:
                                                    '1.0-0'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in" readonly
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="HoatDongDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruHoatDongService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruHoatDongService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':HoatDongDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(HoatDongDataSource!=null && HoatDongDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #HoatDongPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Sự kiện</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="SuKienSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="SuKienSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="SuKienSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="SuKienAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="SuKienDataSource" matSort #SuKienSort="matSort">
                                            <ng-container matColumnDef="Code">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hình đại diện
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Code}}" *ngIf="element.Code"
                                                        title="{{element.Code}}">
                                                        <img class="responsive-img" width="50%" title="{{element.Code}}"
                                                            alt="{{element.Code}}" [src]="element.Code"></a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <a style="cursor: pointer;" title="{{element.Name}}"
                                                        (click)="SuKienAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="SortOrder">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.SortOrder | number:
                                                    '1.0-0'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in" readonly
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="SuKienDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruSuKienService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruSuKienService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':SuKienDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(SuKienDataSource!=null && SuKienDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #SuKienPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Tour</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="TourSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="TourSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="TourSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="TourAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="TourDataSource" matSort #TourSort="matSort">
                                            <ng-container matColumnDef="Code">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hình đại diện
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Code}}" *ngIf="element.Code"
                                                        title="{{element.Code}}">
                                                        <img class="responsive-img" width="50%" title="{{element.Code}}"
                                                            alt="{{element.Code}}" [src]="element.Code"></a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <a style="cursor: pointer;" title="{{element.Name}}"
                                                        (click)="TourAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="SortOrder">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.SortOrder | number:
                                                    '1.0-0'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in" readonly
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="TourDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruTourService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruTourService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':TourDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(TourDataSource!=null && TourDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #TourPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Dịch vụ hỗ trợ - Coupon - Voucher</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="DichVuSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="DichVuSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="DichVuSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="DichVuAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="DichVuDataSource" matSort #DichVuSort="matSort">
                                            <ng-container matColumnDef="Code">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hình đại diện
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Code}}" *ngIf="element.Code"
                                                        title="{{element.Code}}">
                                                        <img class="responsive-img" width="50%" title="{{element.Code}}"
                                                            alt="{{element.Code}}" [src]="element.Code"></a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <a style="cursor: pointer;" title="{{element.Name}}"
                                                        (click)="DichVuAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="SortOrder">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.SortOrder | number:
                                                    '1.0-0'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in" readonly
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="DichVuDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruDichVuService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruDichVuService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':DichVuDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(DichVuDataSource!=null && DichVuDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #DichVuPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Món ăn</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="MonAnSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="MonAnSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="MonAnSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="MonAnAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="MonAnDataSource" matSort #MonAnSort="matSort">
                                            <ng-container matColumnDef="Code">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hình đại diện
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Code}}" *ngIf="element.Code"
                                                        title="{{element.Code}}">
                                                        <img class="responsive-img" width="50%" title="{{element.Code}}"
                                                            alt="{{element.Code}}" [src]="element.Code"></a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <a style="cursor: pointer;" title="{{element.Name}}"
                                                        (click)="MonAnAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="SortOrder">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.SortOrder | number:
                                                    '1.0-0'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="MonAnDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruMonAnService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruMonAnService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':MonAnDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(MonAnDataSource!=null && MonAnDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #MonAnPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- 
               <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Địa điểm</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="DiaDiemSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="DiaDiemSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="DiaDiemSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="DiaDiemDataSource" matSort #DiaDiemSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Địa điểm
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên địa điểm" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="DiaDiemSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="DiaDiemDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruDiaDiemService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruDiaDiemService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':DiaDiemDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(DiaDiemDataSource!=null && DiaDiemDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #DiaDiemPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Nhà hàng</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="NhaHangSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="NhaHangSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="NhaHangSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="NhaHangDataSource" matSort #NhaHangSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Nhà hàng
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên nhà hàng" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="NhaHangSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="NhaHangDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruNhaHangService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruNhaHangService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':NhaHangDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(NhaHangDataSource!=null && NhaHangDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #NhaHangPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                    

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Hỗ trợ</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="HoTroSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="HoTroSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="HoTroSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="HoTroDataSource" matSort #HoTroSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hỗ trợ
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên hỗ trợ" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="HoTroSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="HoTroDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruHoTroService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruHoTroService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':HoTroDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(HoTroDataSource!=null && HoTroDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #HoTroPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> 

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Mạng xã hội</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="SocialSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="SocialSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="SocialSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="SocialDataSource" matSort #SocialSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Mạng xã hội
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Mạng xã hội" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="SocialSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="SocialDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruSocialService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruSocialService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':SocialDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(SocialDataSource!=null && SocialDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #SocialPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Hình đại diện</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <input type="file" accept="image/*" multiple
                                                (change)="HinhAnhChangeFiles($event.target.files)">
                                        </div>
                                    </div>
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <button class="btn btn-primary" [disabled]="!isCoSoLuuTruHinhAnh"
                                                (click)="HinhAnhSubmitFiles()">Upload</button>
                                        </div>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="HinhAnhDataSource" matSort #HinhAnhSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hình đại diện
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Name}}" *ngIf="element.Name">
                                                        <img class="responsive-img" src="{{element.Name}}" />
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="HinhAnhDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruHinhAnhService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruHinhAnhService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':HinhAnhDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(HinhAnhDataSource!=null && HinhAnhDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #HinhAnhPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Video</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <input type="file" accept="video/mp4,video/x-m4v,video/*" multiple
                                                (change)="VideoChangeFiles($event.target.files)">
                                        </div>
                                    </div>
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <button class="btn btn-primary" [disabled]="!isCoSoLuuTruVideo"
                                                (click)="VideoSubmitFiles()">Upload</button>
                                        </div>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="VideoDataSource" matSort #VideoSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Video
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Name}}" *ngIf="element.Name">
                                                        <video class="responsive-img"
                                                            style="max-height: 100px;object-fit: contain;"
                                                            reload="metadata">
                                                            <source src="{{element.Name}}" type="video/mp4">
                                                        </video>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="VideoDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruVideoService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruVideoService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':VideoDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(VideoDataSource!=null && VideoDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #VideoPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->


                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Phê duyệt</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="PheDuyetSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="PheDuyetSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="PheDuyetSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="PheDuyetDataSource" matSort #PheDuyetSort="matSort">
                                            <ng-container matColumnDef="NguoiYeuCauID">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Người yêu cầu
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <div *ngFor="let item of ThanhVienService.list">
                                                        <div *ngIf="item.ID==element.NguoiYeuCauID">
                                                            {{item.Email}}
                                                        </div>
                                                    </div>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayYeuCau">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày yêu cầu
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.NgayYeuCau | date:'dd/MM/yyyy h:mm:ss a'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NoiDungYeuCau">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Yêu cầu
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.NoiDungYeuCau}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayPheDuyet">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    {{element.NgayPheDuyet | date:'dd/MM/yyyy h:mm:ss a'}}
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Phê duyệt / Chưa phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NoiDungPheDuyet">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Nội dung phê duyệt"
                                                        name="NoiDungPheDuyet{{element.ID}}"
                                                        [(ngModel)]="element.NoiDungPheDuyet" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                  
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="PheDuyetService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: PheDuyetService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':PheDuyetDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(PheDuyetDataSource!=null && PheDuyetDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #PheDuyetPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />
<app-loading *ngIf="isShowLoading"></app-loading>