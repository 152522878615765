<div class="row">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Tài khoản
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">Cơ sở lưu trú</a>
                        </li>                        
                        <li class="breadcrumb-item active" style="color: #ffffff;">Tài khoản
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <div id="basic-tabs" class="card card card-default scrollspy">
                            <div class="card-content">
                                <div class="card-title">
                                    <div class="row">
                                        <div class="col s12 m12 l8">
                                            <h5 class="card-title" style="font-size: 14px;">
                                                
                                            </h5>
                                        </div>
                                        <div class="col s12 m12 l4">
                                            <ul class="tabs">
                                                <li class="tab col m3 s12 p-0"><a class="active p-0" href="#1">Tài khoản</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div id="1">
                                    <div class="row">
                                        <form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
                                            <div class="col s6 m6 l12">
                                                <div class="card-title">
                                                    <button
                                                        [disabled]="!ThanhVienService.formDataLogin.TaiKhoan || !ThanhVienService.formDataLogin.MatKhau"
                                                        title="Lưu thay đổi" type="submit" style="padding-top: 3px;"
                                                        class="btn-floating waves-effect waves-light cyan"><i
                                                            class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
                                                </div>
                                            </div>
                                            <input name="ID" [(ngModel)]="ThanhVienService.formDataLogin.ID" type="hidden">                                          
                                            <div class="col s12 m12 l4">
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="TaiKhoan">Tài khoản</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="TaiKhoan"
                                                            [(ngModel)]="ThanhVienService.formDataLogin.TaiKhoan" disabled
                                                            placeholder="Tài khoản" type="text">
                                                    </div>
                                                </div>                                               
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="HoTen">Họ tên</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="HoTen"
                                                            [(ngModel)]="ThanhVienService.formDataLogin.HoTen"
                                                            placeholder="Họ tên" type="text">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="DienThoai">Điện thoại</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="DienThoai"
                                                            [(ngModel)]="ThanhVienService.formDataLogin.DienThoai"
                                                            placeholder="Điện thoại" type="text">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="Email">Email</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="Email"
                                                            [(ngModel)]="ThanhVienService.formDataLogin.Email"
                                                            placeholder="Email" type="email">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="CCCD">CCCD/Hộ chiếu</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="CCCD" [(ngModel)]="ThanhVienService.formDataLogin.CCCD"
                                                            placeholder="CCCD/Hộ chiếu" type="text">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="DiaChi">Địa chỉ</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input name="DiaChi"
                                                            [(ngModel)]="ThanhVienService.formDataLogin.DiaChi"
                                                            placeholder="Địa chỉ" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s12 m12 l4">                                              
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="DanhMucQuocGiaID">Quốc gia</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucQuocGiaID" class="browser-default"
                                                        [(ngModel)]="ThanhVienService.formDataLogin.DanhMucQuocGiaID">
                                                        <mat-option *ngFor="let item of DanhMucQuocGiaService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="DanhMucTinhThanhID">Tỉnh thành</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucTinhThanhID" class="browser-default"
                                                        [(ngModel)]="ThanhVienService.formDataLogin.DanhMucTinhThanhID" (selectionChange)="DanhMucQuanHuyenGetToListAsync()">
                                                        <mat-option *ngFor="let item of DanhMucTinhThanhService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="DanhMucQuanHuyenID">Quận huyện</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucQuanHuyenID" class="browser-default"
                                                        (selectionChange)="DanhMucXaPhuongGetToListAsync()"
                                                        [(ngModel)]="ThanhVienService.formDataLogin.DanhMucQuanHuyenID">
                                                        <mat-option *ngFor="let item of DanhMucQuanHuyenService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>
                                                <div class="col s6 m6 l4">
                                                    <div class="input-field">
                                                        <label for="DanhMucXaPhuongID">Xã phường</label>
                                                    </div>
                                                </div>
                                                <div class="col s6 m6 l8">
                                                    <mat-select
                                                        style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucXaPhuongID" class="browser-default"
                                                        [(ngModel)]="ThanhVienService.formDataLogin.DanhMucXaPhuongID">
                                                        <mat-option *ngFor="let item of DanhMucXaPhuongService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <br />
                                                    <br />
                                                </div>

                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="Note">Ghi chú</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <input disabled name="Note"
                                                            [(ngModel)]="ThanhVienService.formDataLogin.Note"
                                                            placeholder="Ghi chú" type="text">
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l4">
                                                    <div class="input-field">
                                                        <label for="Active">Trạng thái</label>
                                                    </div>
                                                </div>
                                                <div class="col s12 m12 l8">
                                                    <div class="input-field">
                                                        <p>
                                                            <label>
                                                                <input disabled type="checkbox" class="filled-in"
                                                                    name="Active"
                                                                    [(ngModel)]="ThanhVienService.formDataLogin.Active" />
                                                                <span>Phê duyệt / Chưa phê duyệt</span>
                                                            </label>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s12 m12 l4">
                                                <div class="col s4 m4 l4">
                                                    <div class="input-field">
                                                        <label for="AvatarURL">Hình ảnh</label>
                                                    </div>
                                                </div>
                                                <div class="col s8 m8 l8">
                                                    <div class="input-field">
                                                        <input type="file" (change)="changeImage($event.target.files)">
                                                    </div>
                                                    <div class="input-field">
                                                        <img class="responsive-img"
                                                            [src]="ThanhVienService.formDataLogin.AvatarURL">
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />
<app-loading *ngIf="isShowLoading"></app-loading>