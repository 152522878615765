import { Base } from "./Base.model";

export class CoSoLuuTruPhong extends Base{
    GiaCaoNhat?:number;
    GiaThapNhat?:number;
    NgayBatDau?: Date;
    NgayKetThuc?: Date;
}


