import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruTour } from 'src/app/shared/CoSoLuuTruTour.model';
import { CoSoLuuTruTourService } from 'src/app/shared/CoSoLuuTruTour.service';
import { PheDuyet } from 'src/app/shared/PheDuyet.model';
import { PheDuyetService } from 'src/app/shared/PheDuyet.service';
import { CoQuanQuanLy } from 'src/app/shared/CoQuanQuanLy.model';
import { CoQuanQuanLyService } from 'src/app/shared/CoQuanQuanLy.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { DanhMucPheDuyet } from 'src/app/shared/DanhMucPheDuyet.model';
import { DanhMucPheDuyetService } from 'src/app/shared/DanhMucPheDuyet.service';
import { DanhMucNgonNgu } from 'src/app/shared/DanhMucNgonNgu.model';
import { DanhMucNgonNguService } from 'src/app/shared/DanhMucNgonNgu.service';

@Component({
  selector: 'app-co-so-luu-tru-tour-detail',
  templateUrl: './co-so-luu-tru-tour-detail.component.html',
  styleUrls: ['./co-so-luu-tru-tour-detail.component.css']
})
export class CoSoLuuTruTourDetailComponent implements OnInit {
  PheDuyetDataSource: MatTableDataSource<any>;
  @ViewChild('sortPheDuyet') PheDuyetSort: MatSort;
  @ViewChild('paginatorPheDuyet') PheDuyetPaginator: MatPaginator;
  PheDuyetSearchString: string = environment.InitializationString;

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;


  constructor(
    public DanhMucPheDuyetService: DanhMucPheDuyetService,
    public PheDuyetService: PheDuyetService,
    public CoQuanQuanLyService: CoQuanQuanLyService,
    public ThanhVienService: ThanhVienService,
    public CoSoLuuTruTourService: CoSoLuuTruTourService,
    public DanhMucNgonNguService: DanhMucNgonNguService,
    public NotificationService: NotificationService,
    public dialogRef: MatDialogRef<CoSoLuuTruTourDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
  }
  ngOnInit(): void {
    this.DanhMucNgonNguGetAllToListAsync();
    if (this.ID > 0) {
      this.PheDuyetGetByParentIDAndEmptyToListAsync();
      this.ThanhVienGetAllToListAsync();
      this.CoQuanQuanLyGetAllToListAsync();
      this.DanhMucPheDuyetGetAllToListAsync();
    }
  }

  onClose() {
    this.dialogRef.close();
  }
  fileToUpload: any;
  fileToUpload0: File = null;
  changeImage(files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.CoSoLuuTruTourService.formData.Code = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  onSubmit(form: NgForm) {
    this.CoSoLuuTruTourService.SaveAndUploadFileAsync(this.CoSoLuuTruTourService.formData, this.fileToUpload).subscribe(
      res => {
        this.CoSoLuuTruTourService.formData = res as CoSoLuuTruTour;
        this.onClose();
        this.NotificationService.success(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  NgayBatDauChange(value) {
    this.CoSoLuuTruTourService.formData.NgayBatDau = new Date(value);
  }
  NgayKetThucChange(value) {
    this.CoSoLuuTruTourService.formData.NgayKetThuc = new Date(value);
  }
  DanhMucNgonNguGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucNgonNguService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucNgonNguService.list = (res as DanhMucNgonNgu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));       
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ThanhVienGetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.ThanhVienService.list = res as ThanhVien[];
        this.ThanhVienService.list = this.ThanhVienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  CoQuanQuanLyGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoQuanQuanLyService.GetAllToListAsync().subscribe(
      res => {
        this.CoQuanQuanLyService.list = res as CoQuanQuanLy[];
        this.CoQuanQuanLyService.list = this.CoQuanQuanLyService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucPheDuyetGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucPheDuyetService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucPheDuyetService.list = res as DanhMucPheDuyet[];
        this.DanhMucPheDuyetService.list = this.DanhMucPheDuyetService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  //Data PheDuyet
  PheDuyetGetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.PheDuyetService.GetByParentID_CodeToListAsync(this.CoSoLuuTruTourService.formData.ID, CoSoLuuTruTour.name).subscribe(
      res => {
        this.PheDuyetService.list = res as PheDuyet[];
        this.PheDuyetDataSource = new MatTableDataSource(this.PheDuyetService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.PheDuyetDataSource.sort = this.PheDuyetSort;
        this.PheDuyetDataSource.paginator = this.PheDuyetPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  PheDuyetSave(element: PheDuyet) {
    element.ParentID = this.CoSoLuuTruTourService.formData.ID;
    element.Code = CoSoLuuTruTour.name;
    this.PheDuyetService.SaveAsync(element).subscribe(
      res => {
        this.PheDuyetGetByParentIDAndEmptyToListAsync();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  PheDuyetDelete(element: PheDuyet) {
    if (confirm(environment.DeleteConfirm)) {
      this.PheDuyetService.RemoveAsync(element.ID).subscribe(
        res => {
          this.PheDuyetGetByParentIDAndEmptyToListAsync();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}
