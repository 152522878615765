import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTruHuongDanVienDuLich } from 'src/app/shared/CoSoLuuTruHuongDanVienDuLich.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruHuongDanVienDuLichService extends BaseService{
    displayColumns: string[] = ['HoTen', 'CCCD', 'DienThoai', 'Email', 'IsQuocTe','Active','Save'];
    list: CoSoLuuTruHuongDanVienDuLich[] | undefined;
    listSearch: CoSoLuuTruHuongDanVienDuLich[] | undefined;
    listSearch001: CoSoLuuTruHuongDanVienDuLich[] | undefined;
    formData!: CoSoLuuTruHuongDanVienDuLich;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTruHuongDanVienDuLich";
    }
}

