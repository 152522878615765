import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Report } from 'src/app/shared/Report.model';
@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    aPIURL: string = environment.APIURL;
    controller: string = "Download";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
    }
    GetRandomColor(count) {
        var arr = [];
        for (var i = 0; i < count; i++) {
            var letters = '0123456789ABCDEF'.split('');
            var color = '#';
            for (var x = 0; x < 6; x++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
        }
        return color;
    }    
    PageTitle() {        
        return "Visit HCMC";
    }    


    DanhSachCoSoLuuTruToExcelAsync(parentID: number) {
        let url = this.aPIURL + this.controller + '/DanhSachCoSoLuuTruToExcelAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('parentID', JSON.stringify(parentID));          
        return this.httpClient.post(url, formUpload);
    }
    DanhMucTinhThanhToExcelAsync() {
        let url = this.aPIURL + this.controller + '/DanhMucTinhThanhToExcelAsync';
        const formUpload: FormData = new FormData();                
        return this.httpClient.post(url, formUpload);
    }
    DanhMucQuanHuyenToExcelAsync(parentID: number) {
        let url = this.aPIURL + this.controller + '/DanhMucQuanHuyenToExcelAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('parentID', JSON.stringify(parentID));          
        return this.httpClient.post(url, formUpload);
    }
    DanhMucXaPhuongToExcelAsync(parentID: number,tinhThanhID: number) {
        let url = this.aPIURL + this.controller + '/DanhMucXaPhuongToExcelAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('parentID', JSON.stringify(parentID));  
        formUpload.append('tinhThanhID', JSON.stringify(tinhThanhID));  
                
        return this.httpClient.post(url, formUpload);
    }

    ReportThanhVienByDanhMucThanhVienIDToExcelAsync(danhMucThanhVienID: number) {
        let url = this.aPIURL + this.controller + '/ReportThanhVienByDanhMucThanhVienIDToExcelAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('danhMucThanhVienID', JSON.stringify(danhMucThanhVienID));
        return this.httpClient.post(url, formUpload);
    }
    ReportThanhVienByDanhMucThanhVienIDToHTMLAsync(danhMucThanhVienID: number) {
        let url = this.aPIURL + this.controller + '/ReportThanhVienByDanhMucThanhVienIDToHTMLAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('danhMucThanhVienID', JSON.stringify(danhMucThanhVienID));
        return this.httpClient.post(url, formUpload);
    }
    Report001ToHTMLAsync(list: Report[]) {
        let url = this.aPIURL + this.controller + '/Report001ToHTMLAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(list));
        return this.httpClient.post(url, formUpload);
    }
    Report001ToExcelAsync(list: Report[]) {
        let url = this.aPIURL + this.controller + '/Report001ToExcelAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('data', JSON.stringify(list));
        return this.httpClient.post(url, formUpload);
    }
}

