import { Base } from "./Base.model";

export class CoSoLuuTruTour extends Base {
    NgayBatDau?: Date;
    NgayKetThuc?: Date;
    GiaCaoNhat?: number;
    GiaThapNhat?: number;
    DanhMucNgonNguID?: number;
    Description?: string;
}


