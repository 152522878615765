<div class="row">
    <div id="breadcrumbs-wrapper" data-image="https://cms.ctacorporation.vn/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Địa điểm đa chiều <span
                            *ngIf="DiaDiemDaChieuService.list">({{DiaDiemDaChieuService.list.length - 1 }} items)</span>
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Địa điểm đa chiều
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div class="card-content">
                        <div class="col s12 m12 l3">
                            <mat-select
                                    style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                    name="quanHuyenID" class="browser-default" [(ngModel)]="quanHuyenID">
                                    <mat-option [value]="0">
                                        Chọn Quận / Huyện
                                    </mat-option>
                                    <mat-option *ngFor="let item of DanhMucQuanHuyenService.list" [value]="item.ID">
                                        {{item.Name}}
                                    </mat-option>
                                </mat-select>
                        </div>
                        <div class="col s12 m12 l6">
                            <input matInput [(ngModel)]="searchString" placeholder="Tìm ..."
                                class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                (keyup.enter)="onSearch()">
                        </div>
                        <div class="col s12 m12 l3">
                            <a title="Tìm" (click)="onSearch()"
                                class="btn-floating waves-effect waves-light green darken-1"><i
                                    class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                        </div>
                        <div class="KhungOverflow">
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="DanhMucQuanHuyenID">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Quận Huyện
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <mat-select
                                            style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                            name="DanhMucQuanHuyenID{{element.ID}}" class="browser-default"
                                            [(ngModel)]="element.DanhMucQuanHuyenID">
                                            <mat-option [value]="0">
                                                Chọn Quận / Huyện
                                            </mat-option>
                                            <mat-option *ngFor="let item of DanhMucQuanHuyenService.list"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Địa điểm
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <input placeholder="Địa điểm" name="Name{{element.ID}}"
                                            [(ngModel)]="element.Name" type="text">
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Url
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <input placeholder="Url" name="Code{{element.ID}}" [(ngModel)]="element.Code"
                                            type="text">
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="SortOrder">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Sắp xếp
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <input placeholder="0" name="SortOrder{{element.ID}}" style="text-align: right;"
                                            [(ngModel)]="element.SortOrder" type="number">
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Kích hoạt
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <label>
                                            <input type="checkbox" class="filled-in" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active" />
                                            <span>.</span>
                                        </label>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <a title="Lưu thay đổi" (click)="onSave(element)"
                                            class="btn-floating waves-effect waves-light cyan"><i
                                                class="material-icons">save</i></a>&nbsp;&nbsp;&nbsp;
                                        <a style="padding-left: 8px;" (click)="onDelete(element)" *ngIf="element.ID>0"
                                            class="btn-floating waves-effect waves-light red"><i
                                                class="material-icons">delete_outline</i></a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="loading">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Đang tải dữ liệu...
                                    </mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="noData">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Chưa có dữ liệu.
                                    </mat-footer-cell>
                                </ng-container>
                                <mat-header-row
                                    *matHeaderRowDef="DiaDiemDaChieuService.displayColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: DiaDiemDaChieuService.displayColumns;"></mat-row>
                                <mat-footer-row *matFooterRowDef="['loading']"
                                    [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                                <mat-footer-row *matFooterRowDef="['noData']"
                                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                                </mat-footer-row>
                            </mat-table>
                            <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>