import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DatPhongTrucTuyen } from 'src/app/shared/DatPhongTrucTuyen.model';
import { DatPhongTrucTuyenService } from 'src/app/shared/DatPhongTrucTuyen.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';
import { CoSoLuuTruPhong } from 'src/app/shared/CoSoLuuTruPhong.model';
import { CoSoLuuTruPhongService } from 'src/app/shared/CoSoLuuTruPhong.service';
import { DatPhongTrucTuyenDetailComponent } from './dat-phong-truc-tuyen-detail/dat-phong-truc-tuyen-detail.component';

@Component({
  selector: 'app-dat-phong-truc-tuyen',
  templateUrl: './dat-phong-truc-tuyen.component.html',
  styleUrls: ['./dat-phong-truc-tuyen.component.css']
})
export class DatPhongTrucTuyenComponent implements OnInit {

  dataSource: MatTableDataSource<any>
    ;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;

  constructor(
    public DatPhongTrucTuyenService: DatPhongTrucTuyenService,
    public ThanhVienService: ThanhVienService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public CoSoLuuTruPhongService: CoSoLuuTruPhongService,
    public NotificationService: NotificationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.ThanhVienGetAllToListAsync();
    this.CoSoLuuTruGetAllToListAsync();
    this.CoSoLuuTruPhongGetAllToListAsync();
    this.onSearch();
  }
  batDau: Date = new Date();
  ketThuc: Date = new Date();
  onChangeBatDau(value) {
    this.batDau = new Date(value);
  }
  onChangeKetThuc(value) {
    this.ketThuc = new Date(value);
  }
  ThanhVienGetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.ThanhVienService.list = (res as ThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = (res as CoSoLuuTru[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  CoSoLuuTruPhongGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruPhongService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruPhongService.list = (res as CoSoLuuTruPhong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetToListAsync() {
    this.isShowLoading = true;
    this.DatPhongTrucTuyenService.GetBySearchStringOrBatDauAndKetThucAndCoSoLuuTruIDToListAsync(this.searchString, this.batDau, this.ketThuc, this.ThanhVienService.formDataLogin.ParentID).subscribe(
      res => {
        this.DatPhongTrucTuyenService.list = res as DatPhongTrucTuyen[];
        this.dataSource = new MatTableDataSource(this.DatPhongTrucTuyenService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    this.GetToListAsync();
  }
  onSave(element: DatPhongTrucTuyen) {
    this.DatPhongTrucTuyenService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: DatPhongTrucTuyen) {
    if (confirm(environment.DeleteConfirm)) {
      this.DatPhongTrucTuyenService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onAdd(ID: any) {
    this.DatPhongTrucTuyenService.GetByIDAsync(ID).subscribe(
      res => {
        this.DatPhongTrucTuyenService.formData = res as DatPhongTrucTuyen;
        if (ID == 0) {
          this.DatPhongTrucTuyenService.formData.BatDau = new Date();
          this.DatPhongTrucTuyenService.formData.KetThuc = new Date();
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(DatPhongTrucTuyenDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.GetToListAsync();
        });
      },
      err => {
      }
    );
  }
}
